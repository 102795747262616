<template>
  <div>
    <b-card
      no-body
    >
      <b-card-body>
        <router-link :to="{name: 'autoskola-zasposleni-kreiraj'}">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
          >
            {{ $t('DodajZaposlenog') }}
          </b-button>
        </router-link>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-header class="pb-1">
        <div>
          <b-card-title>{{ $t('Zaposleni') }}</b-card-title>
        </div>
        <b-button-group size="sm">
          <b-button
            :variant="filter === 'aktivni' ? 'primary' : 'outline-primary'"
            @click="filter = 'aktivni'"
          >
            {{ $t("Aktivni") }}
          </b-button>
          <b-button
            :variant="filter === 'neaktivni' ? 'primary' : 'outline-primary'"
            @click="filter = 'neaktivni'"
          >
            {{ $t("Neaktivni") }}
          </b-button>
          <b-button
            :variant="filter === 'sve' ? 'primary' : 'outline-primary'"
            @click="filter = 'sve'"
          >
            {{ $t("SviZaposleni") }}
          </b-button>
        </b-button-group>
      </b-card-header>
      <b-card-body>
        <div>
          <b-table
            v-if="Object.entries(items).length"
            :items="items"
            :fields="fields"
            striped
            responsive
            class="mb-0"
          >
            <template #cell(detalji)="row">

              <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
              <b-form-checkbox
                v-model="row.detailsShowing"
                @change="row.toggleDetails"
              />
            </template>
            <template #cell(id)="data">
              <b-button-group
                size="sm"
              >
                <router-link :to="{ name: 'autoskola-zasposleni-izmeni', params: { id: data.item.id }}">
                  <b-button
                    variant="primary"
                    size="sm"
                  >
                    {{ $t('Izmeni') }}
                  </b-button>
                </router-link>
                <router-link
                  v-if="data.item.instruktor"
                  :to="{ name: 'autoskola-zasposleni-casovi', params: { id: data.item.id }}"
                >
                  <b-button
                    variant="info"
                    size="sm"
                  >
                    {{ $t('CasoviPrakticneObuke') }}
                  </b-button>
                </router-link>
                <b-button
                  v-if="data.item.aktivan"
                  variant="danger"
                  @click="modalBrisanje(data.item.id, `${data.item.ime} ${data.item.prezime}`)"
                >
                  {{ $t("Deaktiviraj") }}
                </b-button>
                <b-button
                  v-if="!data.item.aktivan"
                  variant="success"
                  @click="modalAktiviranje(data.item.id, `${data.item.ime} ${data.item.prezime}`)"
                >
                  {{ $t("Aktiviraj") }}
                </b-button>
              </b-button-group>
            </template>

            <!-- full detail on click -->
            <template #row-details="row">
              <b-table-simple
                hover
                small
                responsive
              >
                <b-tbody>
                  <b-tr>
                    <b-th
                      class="col-2"
                    >
                      Email
                    </b-th>
                    <b-td
                      class="col-3"
                      colspan="2"
                    >
                      {{ row.item.email }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th
                      colspan="5"
                      style="padding: 10px;"
                    >
                      {{ $t('Datumi') }}:
                    </b-th>
                  </b-tr>
                  <b-tr
                    v-if="row.item.instruktor"
                    :variant="row.item.isteklo_lekarsko_instruktora ? 'danger' : row.item.istice_lekarsko_instruktora ? 'warning' : ''"
                  >
                    <b-th
                      class="col-3"
                    >
                      {{ $t('DatumIstekaLekarskog') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_lekarskog_instruktora }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.instruktor"
                    :variant="row.item.istekla_licenca_instruktora ? 'danger' : row.item.istice_licenca_instruktora ? 'warning' : ''"
                  >
                    <b-th
                      class="col-3"
                    >
                      {{ $t('DatumIstekaLicenceInstruktora') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_licence_instruktora }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.predavac"
                    :variant="row.item.istekla_licenca_predavaca ? 'danger' : row.item.istice_licenca_predavaca ? 'warning' : ''"
                  >
                    <b-th
                      class="col-3"
                    >
                      {{ $t('DatumIstekaLicencePredavaca') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_licence_predavaca }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.ispitivac"
                    :variant="row.item.istekla_licenca_ispitivaca ? 'danger' : row.item.istice_licenca_ispitivaca ? 'warning' : ''"
                  >
                    <b-th
                      class="col-3"
                    >
                      {{ $t('DatumIstekaLicenceIspitivaca') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_licence_ispitivaca }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </template>

            <template #cell(status)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template>
          </b-table>
          <b-alert
            v-else
            variant="primary"
            show
          >
            <div
              class="alert-body"
              style="padding: 30px;"
            >
              <span>{{ $t('NemaRezultata') }}</span>
            </div>
          </b-alert>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-deaktiviranje-zaposlenog"
      ref="modal-deaktiviranje-zaposlenog"
      ok-variant="danger"
      cancel-variant="outline-secondary"
      :ok-title="$t('Deaktiviraj')"
      :cancel-title="$t('Otkazi')"
      centered
      :title="$t('DeaktivirajZaposlenog')"
      size="lg"
      @ok="deaktivirajZaposlenog"
    >
      <div class="d-block text-left">
        <p>
          {{ $t('DaLiSteSigutniDaZeliteDaDeaktivirateZaposlenog:') }}
        </p>
        <p>
          {{ podaciZaposlenogZaDeaktivaciju.naziv }}
        </p>
      </div>
    </b-modal>
    <b-modal
      id="modal-aktiviranje-zaposlenog"
      ref="modal-aktiviranje-zaposlenog"
      ok-variant="success"
      cancel-variant="outline-secondary"
      :ok-title="$t('Aktiviraj')"
      :cancel-title="$t('Otkazi')"
      centered
      :title="$t('AktivirajZaposlenog')"
      size="lg"
      @ok="aktivirajZaposlenog"
    >
      <div class="d-block text-left">
        <p>
          {{ $t('DaLiSteSigutniDaZeliteDaAktivirateZaposlenog:') }}
        </p>
        <p>
          {{ podaciZaposlenogZaDeaktivaciju.naziv }}
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable, BFormCheckbox, BButton, BCardBody, BBadge, BButtonGroup, BCard, BTableSimple, BTr, BTh, BTd, BTbody, BModal, BCardHeader, BCardTitle, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCardBody,
    BBadge,
    BButtonGroup,
    BCard,
    BCardHeader,
    BCardTitle,
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BTbody,
    BModal,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [{ key: 'detalji', label: this.$i18n.t('Detalji') }, { key: 'ime', label: this.$i18n.t('Ime') }, { key: 'prezime', label: this.$i18n.t('Prezime') }, { key: 'mobilni', label: this.$i18n.t('Mobilni') }, { key: 'id', label: 'Akcije' }],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      podaciZaposlenogZaDeaktivaciju: {},
      filter: 'aktivni',
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    filter: {
      handler() {
        this.pokupiListu() // call it in the context of your component object
      },
    },
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    pokupiListu() {
      this.$http.get(`/autoskole-administratori/zaposleni?filter=${this.filter}`)
        .then(response => { this.items = response.data })
    },
    modalBrisanje(id, naziv) {
      this.podaciZaposlenogZaDeaktivaciju = {
        id,
        naziv,
      }
      this.$bvModal.show('modal-deaktiviranje-zaposlenog')
    },
    modalAktiviranje(id, naziv) {
      this.podaciZaposlenogZaDeaktivaciju = {
        id,
        naziv,
      }
      this.$bvModal.show('modal-aktiviranje-zaposlenog')
    },
    deaktivirajZaposlenog() {
      this.$http.delete(`/autoskole-administratori/zaposleni/${this.podaciZaposlenogZaDeaktivaciju.id}`).then(() => {
        this.$bvModal.hide('modal-deaktiviranje-zaposlenog')
        this.pokupiListu()
      })
    },
    aktivirajZaposlenog() {
      this.$http.delete(`/autoskole-administratori/zaposleni/${this.podaciZaposlenogZaDeaktivaciju.id}`).then(() => {
        this.$bvModal.hide('modal-aktiviranje-zaposlenog')
        this.pokupiListu()
      })
    },
  },
}
</script>
